if (document.querySelector(".hero .swiper")) {

    const swiper = new Swiper(".hero .swiper", {
        loop: true,

        navigation: {
            nextEl: ".hero .swiper-button-next",
            prevEl: ".hero .swiper-button-prev",
        },
        pagination: {
            el: ".hero .swiper-pagination",
        },
    });

}


if (document.querySelector(".configurationSwiper")) {
    const swiper = new Swiper(".configurationSwiper .swiper", {
        slidesPerView: "auto",
        spaceBetween: 30,

        navigation: {
            nextEl: ".configurationSwiper .swiper-button-next",
            prevEl: ".configurationSwiper .swiper-button-prev",
        },

    });
    swiper.on('slideChangeTransitionEnd', function () {
        const slider = document.querySelector(".configurationSwiper .pr__nav-slider");
        let bodyPadding = document.querySelector(".configurationSwiper .pr__nav--count").getBoundingClientRect().left;
        let activeBtn = document.querySelector(".configurationSwiper .tabs__nav-trigger[aria-selected='true']");
        let margin = activeBtn.getBoundingClientRect().left - bodyPadding;
        slider.style.width = (activeBtn.clientWidth) + "px";
        slider.style.marginLeft = margin + "px";
    });
}
if (document.querySelector(".offertSwiperNav")) {
    const swiper = new Swiper(".offertSwiperNav .swiper", {
        slidesPerView: "auto",
        spaceBetween: 30,
        watchSlidesProgress:true,

        navigation: {
            nextEl: ".offertSwiperNav .swiper-button-next",
            prevEl: ".offertSwiperNav .swiper-button-prev",
        },

    });
    swiper.on('slideChangeTransitionEnd', function () {
        const slider = document.querySelector(".offertSwiperNav .pr__nav-slider");
        let bodyPadding = document.querySelector(".offertSwiperNav .pr__nav--count").getBoundingClientRect().left;
        let activeBtn = document.querySelector(".offertSwiperNav .tabs__nav-trigger[aria-selected='true']");
        let margin = activeBtn.getBoundingClientRect().left - bodyPadding;
        slider.style.width = (activeBtn.clientWidth) + "px";
        slider.style.marginLeft = margin + "px";
    });
}


if (document.querySelector(".offertSwiper .swiper")) {

    const blogSwipers = document.querySelectorAll(".offertSwiper");
    for (let i = 0; i < blogSwipers.length; ++i) {
        const swiper = new Swiper(blogSwipers[i].querySelector(".swiper"), {
            loop: false,

            slidesPerView: "auto",
            spaceBetween: 0,

            freeMode: true,

            breakpoints: {
                992: {
                    spaceBetween: 10,
                },
            },

            scrollbar: {
                el: blogSwipers[i].querySelector(".swiper-scrollbar"),
                hide: false,
                draggable: true,
            },
            navigation: {
                nextEl: blogSwipers[i].querySelector(".swiper-button-next"),
                prevEl: blogSwipers[i].querySelector(".swiper-button-prev")
            },
        });
    }
}

if (document.querySelector(".blogSwiper")) {

    const blogSwipers = document.querySelectorAll(".blogSwiper");

    for (let i = 0; i < blogSwipers.length; ++i) {
        const swiper = new Swiper(blogSwipers[i].querySelector(".swiper"), {
            loop: false,

            slidesPerView: "auto",
            spaceBetween: 0,
            freeMode: true,
            breakpoints: {},
            scrollbar: {
                el: blogSwipers[i].querySelector(".swiper-scrollbar"),
                hide: false,
                draggable: true,
            },
            navigation: {
                nextEl: blogSwipers[i].querySelector(".swiper-button-next"),
                prevEl: blogSwipers[i].querySelector(".swiper-button-prev")
            },
            breakpoints: {
                992: {
                    spaceBetween: 20,
                },
            },


        });
    }

}


if (document.querySelector(".pCard__left .swiper")) {

    const swiper = new Swiper(".pCard__left .swiper", {
        loop: true,

        slidesPerView: 1,
        breakpoints: {
            576: {
                slidesPerView: 2,
                spaceBetween: 0,
            },
        },

        navigation: {
            nextEl: ".pCard__left .swiper-button-next",
            prevEl: ".pCard__left .swiper-button-prev",
        },
        pagination: {
            el: ".pCard__left .swiper-pagination",
        },
    });

}
